@import 'src/styles/services';

.root {
  cursor: pointer;
  scroll-snap-align: center;
  position: relative;
  aspect-ratio: 160 / 140;
  border-radius: $border-lg;
}

.full {
  width: 100%;
  height: 100%;
}

.fix {
  width: clamp(140px, 25vw, 310px);
}

.contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.arrow {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-color: $color-black-light;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 49%, 100% 22%, 0% 37%);
}
