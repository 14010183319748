@import 'src/styles/services';

.root {
  position: relative;
  display: grid;
  row-gap: 16px;

  .moreButton {
    column-gap: 4px;
    padding-inline: 8px;
    font-size: 12px;
    line-height: 2;
    border-radius: 100vw;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  height: 32px;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  display: flex;

  &.uppercaseHeaderVariant {
    text-transform: uppercase;
  }

  &.capitalizeHeaderVariant {
    text-transform: capitalize;
  }

  sup {
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    top: -3px;
    color: $color-grey;
    vertical-align: text-top;
    margin-left: 8px;
  }

  .text {
    white-space: nowrap;
  }
}

.icon {
  width: 12px;
  height: 22px;
  margin-right: -4px;
}

@include media-width-down($screen-xs) {
  .title {
    height: 24px;
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;

    sup {
      font-size: 10px;
      top: -2px;
    }

    .text {
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }

  .header {
    min-height: 24px;
  }
}
