@import 'src/styles/services';

.root {
  padding-bottom: 32px;
}

.loader {
  position: relative;
  min-height: 295px;
}

.section {
  margin-top: 32px;
  padding: 0 10px;

  &.hidden {
    display: none;
  }
}

.notFound {
  margin-top: 20px;

  @include media-width-down($screen-xs) {
    margin-top: 42px;
  }
}
