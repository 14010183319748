@import 'src/styles/services';

.root {
  display: flex;
  align-items: center;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-yellow;
  border-radius: 10px 0;
  font-weight: 900;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-white;
  margin-left: 16px;
  height: 24px;
  padding: 0 9px;
}
