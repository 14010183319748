@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  position: relative;
}

.title {
  font-weight: 900;
  font-size: 40px;
  line-height: 100%;
  margin: 16px 8px;
  text-transform: uppercase;
  color: $color-yellow;
  text-align: center;
}

.text {
  color: $color-grey;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
}
