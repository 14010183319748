@import 'src/styles/services';

$screen-1127: 1127px;

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-black-light;
  background-image: url(/assets/carousel/images/background.webp);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  position: relative;

  @include media-width-down($screen-1127) {
    display: none;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 330px;
    width: 175px;
    inset: auto 0 16px auto;
    background-image: url(/assets/winners/images/frame.webp);
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @include media-width-down($screen-md) {
    &::after {
      display: none;
    }
  }
}

.header {
  font-size: 22px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  padding: 16px 16px 8px 16px;
  border-bottom: 1px solid $color-black;
}

.winners {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px 16px 16px 16px;
  max-width: calc(100% - 175px);
}

.winner {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  grid-template-areas:
    'image title'
    'image subtitle';
  cursor: pointer;

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    inset: 0;
    display: block;
    transition: trs(background-color, backdrop-filter);
  }

  &:hover {
    &::after {
      opacity: 1;
      backdrop-filter: grayscale(100%) blur(1px);
    }
  }
}

.image {
  overflow: hidden;
  border-radius: 10px;
  grid-area: image;
  border: 1px solid $color-grey;
  width: 50px;
  height: 40px;
  position: relative;
}

.title {
  font-size: 15px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  grid-area: title;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  grid-area: subtitle;
  display: flex;
  gap: 8px;
  font-size: 13px;
  line-height: 1.2;
  color: $color-yellow;
}

.name {
  max-width: 116px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount {
  background-color: $color-red;
  font-weight: bold;
  padding-inline: 8px;
  color: $color-white;

  span {
    vertical-align: middle;
  }
}

.slider {
  height: 346px;

  @include media-width-down($screen-md) {
    margin-left: 0;
  }
}

.actions {
  position: absolute;
  z-index: 10;
  transform: scale(0);
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: 10px;
  width: 272px;
  opacity: 0;
  transition: trs(transform, opacity);
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;

  .winner:hover & {
    transform: translate3d(0, 0, 0); // fixes Safari issue https://jira.dats.tech/browse/MWL-15929
    opacity: 1;
  }
}

.button {
  z-index: 11;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 0 8px;
}
