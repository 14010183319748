@import 'src/styles/services';

.root {
  background: linear-gradient(180deg, #292c33 0%, rgba(41, 44, 51, 0) 100%);
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0 18px 16px;
  gap: 16px;
  border-radius: 10px;
  margin: 0 8px 0 10px;
  width: 260px;
}
