@import 'src/styles/services';

$screen-1127: 1127px;
$screen-1615: 1615px;
$screen-1400: 1400px;

.banner {
  display: flex;
  padding: 0 10px;
  margin-bottom: 8px;
  flex-wrap: nowrap;
  gap: 10px;

  @include media-width-down($screen-sm) {
    gap: 0;
  }
}

.carousel {
  flex-grow: 9999;

  @include media-width-down($screen-1127) {
    width: 100%;
  }
}

.contentHidden {
  display: none;
}

.winners {
  display: flex;
  width: 469px;
  max-width: 469px;
  place-content: center;
  place-items: stretch;

  @include media-width-down($screen-md) {
    display: none;
  }
}
