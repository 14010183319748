@import 'src/styles/services';

.root {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px 16px;
  padding-right: 20px;

  @include media-width-down($screen-xs) {
    grid-template-columns: 1fr;
  }
}
