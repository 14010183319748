@use 'sass:math';

@function trs($params...) {
  $result: ();
  $duration: 0.2s;
  $func: ease;

  @each $param in $params {
    $result: append($result, $param $duration $func, comma);
  }

  @return $result;
}

@mixin aspect($width, $height) {
  aspect-ratio: $width / $height;

  @supports not (aspect-ratio: $width / $height) {
    &::before {
      content: '';
      float: left;
      padding-top: calc((#{$height} / #{$width}) * 100%);
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

@mixin absCenter($axis: 'all') {
  @if ($axis == 'all') {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @if ($axis == 'x') {
    left: 50%;
    transform: translateX(-50%);
  }

  @if ($axis == 'y') {
    top: 50%;
    transform: translateY(-50%);
  }
}
