@import 'src/styles/services';

.root {
  position: relative;
  max-height: inherit;
  height: inherit;
  overflow: initial;
  display: flex;
  flex-direction: column;
}
