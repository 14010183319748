@import 'src/styles/services';

.root,
.dropdownsWrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;

  @include media-width-down($screen-xs) {
    gap: 16px;
  }
}

.root {
  justify-content: center;
  height: 50px;
  border-bottom: 1px solid $color-black-light;
  position: relative;
  background-color: $color-black;
  margin: 0 10px;

  .dropdown {
    @include media-width-down($screen-xs) {
      position: initial;
    }
  }

  .dropdownTitle {
    @include media-width-down($screen-xxs) {
      font-size: 12px;
    }
  }

  .dropdownContainer {
    background-color: $color-black;
    border-radius: 0 0 10px 10px;
    padding: 16px 8px 24px 16px;
    width: max-content;

    @include media-width-down($screen-xs) {
      padding: 16px;
      margin-top: 40px;
      width: 100%;
    }
  }

  @include media-width-down($screen-xs) {
    justify-content: space-between;
    padding: 0 8px;
    height: 40px;

    &.active {
      margin-bottom: 40px;
    }
  }
}

.dropdownsWrapper {
  @include media-width-down($screen-xs) {
    justify-content: start;
    padding: 0 8px;
    position: absolute;
    top: calc(100% + 1px);
    background-color: $color-black;
    border-bottom: 1px solid $color-black-light;
    height: 40px;
    width: 100%;
    z-index: 10;
    display: none;

    &.active {
      display: grid;
    }
  }
}

.filtersToggle {
  display: none;

  .filterIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: $color-grey;
  }

  .arrowIcon {
    width: 10px;
    height: 8px;
    margin-left: 13px;
    transition: trs(transform);
  }

  &.active .arrowIcon {
    transform: rotate(-180deg);
  }

  @include media-width-down($screen-xs) {
    display: flex;
  }
}

.searchIcon {
  width: 24px;
  height: 24px;
}

.root .searchField {
  justify-content: center;
  width: 49px;
  overflow: hidden;
  transition: trs(width);

  &.active {
    width: 236px;
    box-shadow: 0 1px 0 0 $color-red;

    @include media-width-down($screen-xs) {
      width: 200px;
    }
  }

  .input {
    border-color: transparent;
    border-radius: 0;
    height: 100%;

    &:global(.form-field_focused) {
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }

  @include media-width-down($screen-xs) {
    min-width: 100%;
  }
}
