@import 'src/styles/services';

$screen-1127: 1127px;
$border-radius: 10px;
$box-shadow: 4px 0 13px 8px rgba(20, 20, 20, 0.8);
$aspect-ratio: 787 / 422;

.root {
  position: absolute;
  border-radius: $border-radius;
  aspect-ratio: $aspect-ratio;
  width: var(--carousel-width, 787px);
  background-color: $color-black-light;
  background-image: url(/assets/carousel/images/background.webp);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  place-content: center;
  place-items: center;
  transition: trs(transform);
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &::after,
  &::before {
    content: '';
    outline: none;
    display: block;
    position: absolute;
    border-radius: $border-radius;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: $box-shadow;
    transform: trs(opacity);
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    opacity: 1;
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

.contentContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 0;
  padding: 16px 16px 46px;

  @include media-width-down($screen-xs) {
    padding: 25px 16px 44px;
  }
}

.active {
  cursor: default;

  .contentContainer {
    z-index: 1;
  }

  &.hasUrl {
    cursor: pointer;
  }

  &::before {
    opacity: 0;
  }
}

.left::after,
.left::before {
  opacity: 0;
}

.right {
  @include hover {
    &::before {
      opacity: 0.5;
    }
  }
}

.pagination {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  column-gap: 8px;
  z-index: calc(var(--children-count) + 1);
  opacity: 1;
  transition: trs(opacity);

  &.hidden {
    opacity: 0;
  }

  @include media-width-down($screen-xs) {
    opacity: 0;
    pointer-events: none;
  }
}

.prev,
.next {
  width: 24px;
  height: 24px;
  color: $color-grey;
  transition: trs(color);
  cursor: pointer;

  @include hover {
    color: $color-grey-light;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    color: darken($color-grey, 10%);
  }
}

.slider {
  position: relative;
  font-size: 128px;
  user-select: none;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 50px;
  font-weight: 900;
  line-height: 50px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  color: $color-white;
  white-space: pre;
  margin-bottom: 8px;

  @include media-width-down($screen-xs) {
    font-size: 29px;
    line-height: 29px;
  }
}

.subtitle {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre;

  @include media-width-down($screen-xs) {
    display: none;
  }
}

.button {
  @include media-width-down($screen-xs) {
    width: 100%;
  }
}
