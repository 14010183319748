@import 'src/styles/services';

.wrapper {
  background-color: $color-black;
  padding: 8px;
}

.root {
  position: relative;
  border-radius: 10px;
  display: flex;
  column-gap: 16px;
  padding: 8px 0;
  background-color: $color-black-light;
}

.container {
  display: flex;
  column-gap: 16px;
  overflow: hidden;
  width: 100%;
}

.scrollable {
  @include remove-scrollbar;

  display: flex;
  column-gap: 8px;
  overflow-x: scroll;
  padding: 0 12px;
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0,
    rgba(0, 0, 0, 1) calc(100% - 20px),
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0) 0)
    100% 50% / 100% 100% repeat-x;
  width: 100%;
}

@include media-width-down($screen-md) {
  .root {
    padding-right: 0;
    scroll-padding-inline-end: 0;
  }
}