@import 'src/styles/services';

.root {
  display: flex;
  column-gap: 8px;
  z-index: calc(var(--children-count) + 1);
  position: absolute;
  bottom: 16px;
  left: 16px;
  transition: trs(left, transform);
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  @include media-width-down($screen-sm) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.page {
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-color: $color-grey;

  &.active {
    background-color: $color-red;
  }
}
