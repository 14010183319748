@import 'src/styles/services';

.clearBtn {
  color: $color-grey;
  transition: trs(opacity);
}

.searchBtn {
  width: 20px;
  height: 20px;
  color: $color-grey;
}
