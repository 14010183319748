@import 'src/styles/services';

.root {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  height: min-content;
  min-height: 19px;
  padding: 4px;
  font-size: 11px;
  font-weight: 800;
  line-height: 1.31px;
  text-transform: uppercase;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: trs(clip-path);
  background-color: $color-grey;
}

.top,
.banzai_choice {
  background-color: $color-red;
}

.vip {
  background-color: $color-yellow;
}

.new {
  background-color: $color-grey-light;
}

.tournament {
  background-color: $color-pink;
}

.rm {
  background-color: $color-green-soft;
}

.sale {
  background-color: $color-blue;
}

.best_seller {
  background-color: $color-blue-dark;
}

.action {
  background-color: $color-aviator;
}

/* Skew variant */
.leftSkew {
  clip-path: polygon(0 12.5%, 100% 0, 100% 100%, 0 87.5%);
}

.rightSkew {
  clip-path: polygon(0 0, 100% 12.5%, 100% 87.5%, 0% 100%);
}
