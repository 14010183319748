@import 'src/styles/services';

$screen-1078: 1078px;

.root {
  display: grid;
  grid-template-columns: 100%;

  @include media-width-down($screen-sm) {
    --carousel-width: 100%;
  }

  &.withSidebar {
    grid-template-columns: minmax(auto, max-content) minmax(0, 1fr);

    @include media-width-down($screen-1078) {
      --carousel-width: 100%;
    }
  }
}

.layout {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 36px;

  @include media-width-down($screen-xs) {
    padding-bottom: 4px;
  }
}

.content {
  .withSidebar & {
    margin-left: -10px;
  }
}
