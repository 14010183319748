@import 'src/styles/services';

.wrapper {
  margin-top: -8px;
  height: auto;
}

.floatContainer {
  position: relative;
}

@include media-width-down($screen-850) {
  .floatContainer {
    z-index: $mobile-float-menu-z-index;
  }

  .visible {
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    text-align: center;
    width: 100%;
  }

  .wrapper {
    margin-top: 0;
  }
}
