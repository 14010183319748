@import 'src/styles/services';

$colors: (
  yellow: $color-yellow,
);

.root {
  display: flex;
  column-gap: 8px;
  overflow: initial;
  scroll-snap-align: start;
  user-select: none;
  font-size: 16px;
  font-weight: 900;
  width: 100%;
  justify-content: left;
  min-width: 60px;

  .count {
    position: absolute;
    top: -1px;
    right: 16px;
    padding: 2px 8px 2px 8px;
    border-radius: 10px 0 10px 0;
    font-size: 15px;
    font-weight: 900;
    text-align: center;
    background: $color-grey;
    color: white;
  }

  .icon {
    transition: trs(color);
    color: $color-grey;
  }

  &.active .icon {
    color: $color-white;
  }

  @include hover {
    .icon {
      color: $color-white;
    }

    &:not(.active) {
      color: $color-white;
    }
  }

  @each $name, $color in $colors {
    &.#{$name}Color {
      color: $color;

      .icon {
        color: $color;
      }

      @include hover {
        color: $color;
      }
    }
  }
}

@include media-width-down($screen-850) {
  .root {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 9px;
    min-width: 56px;
    color: #a0a4ac;

    .count {
      position: absolute;
      top: 0;
      right: auto;
      left: 48px;
      padding: 0 2px;
      border-radius: 2px 0 2px 0;
      font-size: 9px;
      background: $color-grey;
      color: inherit;
    }

    @each $name, $color in $colors {
      &.#{$name}Color {
        background: radial-gradient(50% 50% at 50% 50%, rgba($color, 0.18) 0%, rgba($color, 0) 100%);
      }
    }
  }

  .text {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 9px;
    font-weight: 900;
    line-height: 8.8px;
    letter-spacing: 0.05em;
  }
}
