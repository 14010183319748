@import 'src/styles/services';

.root {
  @include remove-scrollbar();

  @include media-width-down($screen-xxs) {
    overflow-x: auto;
  }
}

.buttonContainer {
  .button {
    @include title(h3, 900);

    white-space: pre-wrap;
    height: 100%;
    position: relative;
    box-shadow: none;

    &::before {
      content: '';
      position: absolute;
      height: 32px;
      width: 52px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='52' height='32' viewBox='0 0 52 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 0H42H51.5L0 32L20 0Z' fill='%23141414'/%3E%3C/svg%3E%0A");
    }

    &::after {
      content: '';
      position: absolute;
      height: 45px;
      width: 51px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='51' height='45' viewBox='0 0 51 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.5 45L22.5 45H29H38L44 25L23.7881 42.9662L51 0L0 45H11.5H21.5Z' fill='%23141414'/%3E%3C/svg%3E%0A");
    }
  }

  @include media-width-down($screen-xs) {
    height: 32px;
    grid-column: 1 / -1;

    .button {
      @include text(md, 700);

      &::before,
      &::after {
        content: none;
      }
    }
  }
}
