@import 'src/styles/services';

$aspect-ratio: 787 / 422;

.root {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  z-index: 0;
}

.wrapper {
  overflow: visible;
  position: relative;
  height: inherit;
  display: flex;
  flex-wrap: nowrap;
  transition: trs(transform);
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &::after {
    content: '';
    display: block;
    position: relative;
    visibility: hidden;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    aspect-ratio: $aspect-ratio;
    width: 787px;

    @include media-width-down($screen-sm) {
      width: 100%;
    }
  }
}
