@import 'src/styles/services';

.root {
  cursor: pointer;
  scroll-snap-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  place-content: center;
  place-items: center;

  @include aspect(230.5, 153);

  border-radius: 10px;
  isolation: isolate;
  z-index: 1;

  @include media-width-down($screen-md) {
    @include aspect(204, 136);
  }

  @include media-width-down($screen-sm) {
    @include aspect(160, 118);
  }

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    inset: 0;
    display: block;
    transition: trs(background-color, backdrop-filter);
  }

  &::after {
    z-index: 3;
    opacity: 0;
    background-color: rgba($color-black, 0.75);
    transition: trs(opacity);
  }

  &:hover {
    &::after {
      opacity: 1;
      backdrop-filter: grayscale(100%) blur(1px);
    }
  }
}

.background {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.backgroundImage {
  object-fit: cover;
  object-position: center;
}

.labels {
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: column;
  transition: trs(filter);

  .root:hover & {
    filter: grayscale(100%);
  }
}

.label {
  margin-bottom: 1px;
}

.actions {
  position: absolute;
  inset: 0;
  display: flex;
  padding: 8px;
  opacity: 0;
  transform: scale(0);
  transition: trs(transform, opacity);
  z-index: 5;

  .root:hover & {
    transform: translate3d(0, 0, 0); // fixes Safari issue https://jira.dats.tech/browse/MWL-15929
    opacity: 1;
  }
}

.actionsWrapper {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.providerButton {
  position: absolute;
  top: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
  white-space: break-spaces;
  text-align: center;
}

.playButtonContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.playButton {
  max-width: 110px;
  height: 24px;
  padding: 0 22px;
  text-transform: uppercase;
  border-radius: 5px;
}

.playIcon {
  min-width: 46px;
  min-height: 46px;

  &::after {
    content: '';
    position: absolute;
    width: 46px;
    height: 46px;
    background-color: $color-red;
    border-radius: 50%;
    z-index: -1;
    bottom: 0;
    right: 0;
  }

  &:hover {
    clip-path: polygon(0% 45%, 27% 70%, 0% 54%, 0 100%, 100% 100%, 100% 60%, 72% 33%, 100% 50%, 100% 0, 0 0);
  }
}

.demoButton {
  position: absolute;
  bottom: 0;
  font-weight: 900;
  height: 24px;
}

.limits {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: $color-black;
  color: #a4a8af;
  z-index: 4;
  font-size: 10px;
  padding-inline: 6px;
  padding-block: 4px;
  clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
}

// Variants

.defaultButtonSize {
  min-width: 46px;
  min-height: 46px;

  &::after {
    width: 46px;
    height: 46px;
  }
}

.smallButtonSize {
  min-width: 28px;
  min-height: 27px;

  &::after {
    width: 28px;
    height: 27px;
  }
}
